import React from 'react';

// Components
import CoursesLanding from "../../components/Courses/CoursesLanding";

function Courses(props) {

    return (
        <div className="page-container">
            <div className="page-column">
                <CoursesLanding />
            </div>
        </div>
    );
}

export default Courses;