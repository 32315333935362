import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// assets/img/styler_secondary_logo.png
import stylerLogo from '../../assets/img/styler_logo_secondary.svg';
import CoursesLandingGraphic from '../../assets/img/CoursesLandingGraphic.png';

function CoursesLanding(props) {
    return (
        <Box sx={{ justifyContent: 'center', display: 'flex', flex: 1, my: 4 }} >
            <Grid container spacing={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={11} sm={10} md={5} lg={4} xl={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
                        <Box>
                            <img src={stylerLogo} alt="Styler Logo" style={{ width: 250 }} />
                            <Typography variant="h3" sx={{ fontWeight: '600', letterSpacing: '0.22em', color: '#0a122b' }}>Courses</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h6">Group bookings, tailored training, and more—fully integrated with your booking platform, app and widget.</Typography>
                            {/*<Typography variant="h6">Group bookings, tailored training, and more—get started by creating your first course.</Typography>*/}
                        </Box>
                        <Box>
                            <Typography variant="h5" sx={{ color: 'grey', fontWeight: '400' }}>Coming December 2024</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
                            <Button variant="contained" color="primary" disabled>Create course</Button>
                            <Button variant="outlined" color="primary" disabled>Learn more</Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={11} sm={10} md={5} lg={4} xl={3}>
                    <Box>
                        <img src={CoursesLandingGraphic} alt="Courses" style={{ width: '100%' }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CoursesLanding;