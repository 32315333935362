// MUI components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
// Custom components
import Card from "components/Material/Card.js";
// Assets
import stripeLogo from "assets/img/logos/stripe/stripeLogo.svg";
import stripeBackground from "assets/img/logos/stripe/stripeBackground.jpg";

function Connect(props) {
    const { onConnect, loading, onboarded, stripeAccountLinkUrl } = props;
    return (
        <div className="page-column">
            <Box sx={{ display: 'flex', flex: 1, my: 4 }} >
                <Grid container spacing={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} sm={8} md={6} lg={5} xl={4} style={{ maxWidth: 600 }}>
                        <Card style={{ padding: 0 }}>
                            <div style={{ overflow: 'hidden' }}>
                                <img
                                    src={stripeBackground}
                                    alt="Stripe background"
                                    style={{ height: 120, width: '100%', objectFit: 'cover' }}
                                />
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 3, p: '1rem' }}>
                                <img
                                    src={stripeLogo}
                                    alt="Stripe logo"
                                    style={{ height: 70 }}
                                />
                                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                    Styler partners with Stripe to process deposit payments, no-show fees and late-cancellation fees. Connect your Stripe account to Styler and start processing payments in minutes.
                                </Typography>
                                {!stripeAccountLinkUrl && (
                                    <LoadingButton
                                        variant="contained"
                                        onClick={onConnect}
                                        loading={loading}
                                        sx={onboarded ? { backgroundColor: 'green' } : undefined}
                                    >
                                        {onboarded ? 'Connected' : 'Connect'}
                                    </LoadingButton>
                                )}
                                {stripeAccountLinkUrl && (
                                    <>
                                        <Alert severity="warning" sx={{ width: '100%' }}>Additional information is required to complete Stripe onboarding.</Alert>
                                        <LoadingButton
                                            variant="contained"
                                            loading={loading}
                                            href={stripeAccountLinkUrl}
                                            sx={{ textDecoration: 'none', color: '#FFFFFF' }}
                                        >
                                            Complete onboarding
                                        </LoadingButton>
                                    </>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Connect;