import React from "react";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// screen components
import Connect from "components/Stripe/Connect";

import Api from 'api/index';

import { bindActionCreators } from 'redux';
import * as serviceActions from 'actions/index';
import { connect } from "react-redux";

class Stripe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stripeAccountId: null,
            stripeAccountLinkUrl: null,
            onboarded: false,
            firstConnect: false
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('return') || urlParams.has('refresh')) {
            this.onConnect();
            return;
        }
        this.getStripeAccount();
    }

    getStripeAccount = async () => {
        if(!this.state.loading) {
            this.setState({ loading: true });
        }
        try {
            const { data } = await Api.getStripeAccount();
            if(!data.stripeAccountId) {
                this.setState({ loading: false, firstConnect: true });
                return;
            }
            this.setState({ stripeAccountId: data.stripeAccountId, loading: false, onboarded: true });
        } catch (error) {
            if(error?.response?.data?.code === 'account_not_onboarded') {
                this.onConnect();
                return;
            }
            this.props.triggerNotification("An error occured whilst retrieving your Stripe account. Please contact support if this problem persists.", "danger", "bc", 6);
            this.setState({ loading: false });
        }
    }

    onConnect = async () => {
        if (this.state.onboarded) return;
        this.setState({ loading: true });
        try {
            const { data } = await Api.postStripeAccount();
            if(this.state.firstConnect) {
                window.location.href = data.accountLinkUrl;
            } else {
                this.setState({ stripeAccountLinkUrl: data.accountLinkUrl, loading: false });
            }
        } catch (error) {
            if(error?.response?.data?.code === 'account_onboarded') {
                this.getStripeAccount();
                return;
            }
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <>
                <PanelHeader size="sm"/>
                <div className="content">
                    <Connect
                        onConnect={() => {
                            if(this.state.loading) return;
                            this.onConnect();
                        }}
                        loading={this.state.loading}
                        onboarded={this.state.onboarded}
                        stripeAccountLinkUrl={this.state.stripeAccountLinkUrl}
                    />
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        business: state.business
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(serviceActions, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);