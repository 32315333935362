/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import moment from 'moment-timezone';
import axios from 'axios';
import Lottie from 'react-lottie';
import * as weatherClearDay from 'assets/animations/weather/weather-clear-day.json';
import * as weatherClearNight from 'assets/animations/weather/weather-clear-night.json';
import * as weatherClouds from 'assets/animations/weather/weather-clouds.json';
import * as weatherMist from 'assets/animations/weather/weather-mist.json';
import * as weatherRainDay from 'assets/animations/weather/weather-rain-day.json';
import * as weatherSnow from 'assets/animations/weather/weather-snow.json';
import * as weatherThunder from 'assets/animations/weather/weather-thunder.json';

import { FiGlobe, FiCalendar, FiMapPin, FiBook, FiPlus } from 'react-icons/fi';
import { IoWalkOutline } from 'react-icons/io5';
import { FaCircle } from 'react-icons/fa';
import Api from '../../api/index';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as serviceActions from '../../actions/index';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Clock from "components/Clock/Clock.js";
import ToDoAdd from "components/ToDoAdd/ToDoAdd.js";

import timezoneOptions from "constants/timezone";
import { CDN_URL } from "constants/urls";

class Home extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            curTime: new Date(),
            weatherData: null,
            toDoAddVisible: false,
            staffHoursData: [],
            minTime: null,
            maxTime: null,
            timeStatus: 2,
            bookingCount: '--',
            walkInCount: '--'
        };
        this.toggleToDoAddVisible = this.toggleToDoAddVisible.bind(this);
    }

    componentDidMount() {
      let that = this;
      setInterval(() => {
          that.setState({
              curTime : new Date()
          });
      }, 1000);
      this.props.actions.loadToDo();
      // Only load staff hours and bookings counts if locations have loaded
      if(this.props.businessLocation?.length > 0) {
        if(!this.props.loading?.isLoading) {
          this.props.actions.loadLoadingSpinner(true);
        }
        this.loadLocationData();
      } else {
        this.props.actions.loadLoadingSpinner(false);
      }
    }

    componentDidUpdate(prevProps) {
      // Detect location load or selected location change
      if((prevProps.settings.selected_business_location_id !== this.props.settings.selected_business_location_id) ||
        (prevProps.businessLocation?.length === 0 && this.props.businessLocation?.length > 0)) {
        this.loadLocationData();
      }
    }

    loadLocationData = () => {
      this.getWeatherData();
      this.getStaffHours();
      this.getBookingCount();
    };

    toggleToDoAddVisible = () => {
      this.setState({
        toDoAddVisible: !this.state.toDoAddVisible
      });
    };

    async getWeatherData() {
        try {
          let businessLocationObj = this.props.businessLocation.find(x => x.business_location_id === this.props.settings.selected_business_location_id);
          let businessLatitude, businessLongitude;
          if(businessLocationObj) {
            businessLatitude = businessLocationObj.address_latitude;
            businessLongitude = businessLocationObj.address_longitude;
          } else {
            businessLatitude = this.props.business.address_latitude;
            businessLongitude = this.props.business.address_longitude;
          }
          if(!businessLatitude || !businessLongitude) {
            businessLatitude = 51.507351;
            businessLongitude = -0.127758;
          }
          const { data } = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${businessLatitude}&lon=${businessLongitude}&appid=b73271931744b3f63a57e1e28101368b`);
          this.setState({ weatherData: data });
        } catch(e) {
          console.log(e);
        }
    }

    getStaffHours() {
      this.props.actions.loadLoadingSpinner(true);
      let that = this;
      // Current date
      let refDate;
      if(this.props.business.timezone_name) {
        refDate = moment().tz(this.props.business.timezone_name, false).format('YYYY-MM-DD');
      } else {
        refDate = moment().format('YYYY-MM-DD');
      }
      // Get business location
      let enabledBusinessLocations = this.props.businessLocation.filter(x => x.enabled === 1);
      if(!this.props.businessLocation || enabledBusinessLocations.length === 0) {
        return;
      }
      let businessLocationId;
      if(this.props.settings && this.props.settings.selected_business_location_id) {
        businessLocationId = this.props.settings.selected_business_location_id;
      } else {
        businessLocationId = enabledBusinessLocations[0].business_location_id;
      }
      Api.getStaffHours(refDate, refDate, businessLocationId).then(f => {
        return f.data;
      }).then((result) => {
        that.setState({ staffHoursData: result.staffHoursData });
        let filteredHoursData = result.staffHoursData.filter(x => x.working === 1);
        let minTimeObj = null, maxTimeObj = null;
        if(filteredHoursData.length > 0){
          minTimeObj = filteredHoursData.reduce((prev, current) => (prev.start < current.start) ? prev : current);
          maxTimeObj = filteredHoursData.reduce((prev, current) => (prev.end > current.end) ? prev : current);
        }
        that.setState({ minTime: minTimeObj, maxTime: maxTimeObj, timeStatus: result.staffHoursData[0].time_status });
        that.props.actions.loadLoadingSpinner(false);
      }).catch(error => {
        console.log(error);
        that.props.actions.loadLoadingSpinner(false);
      });
    }

    getBookingCount() {
      let that = this;
      // Current date
      let refDate;
      if(this.props.business.timezone_name) {
        refDate = moment().tz(this.props.business.timezone_name, false).format('YYYY-MM-DD');
      } else {
        refDate = moment().format('YYYY-MM-DD');
      }
      // Get business location
      let enabledBusinessLocations = this.props.businessLocation.filter(x => x.enabled === 1);
      if(!this.props.businessLocation || enabledBusinessLocations.length === 0) {
        return;
      }
      let businessLocationId;
      if(this.props.settings && this.props.settings.selected_business_location_id) {
        businessLocationId = this.props.settings.selected_business_location_id;
      } else {
        businessLocationId = enabledBusinessLocations[0].business_location_id;
      }
      Api.getBookingCount(refDate, refDate, businessLocationId).then(f => {
        return f.data;
      }).then((result) => {
        that.setState({ bookingCount: result.bookingCountData, walkInCount: result.walkInCountData });
      }).catch(error => {
        console.log(error);
      });
    }

    loadWeatherAnimation() {
      if(this.state.weatherData){
        let animation = null;
        const weatherMain = this.state.weatherData.weather[0].main;
        if(weatherMain === "Clear"){
          let hourInt;
          if(this.props.business.timezone_name) {
            hourInt = moment().tz(this.props.business.timezone_name, false).hour();
          } else {
            hourInt = moment().hour();
          }
          if(hourInt < 6 || hourInt > 18) {
            animation = weatherClearNight;
          } else {
            animation = weatherClearDay;
          }
        } else if(weatherMain === "Drizzle" || weatherMain === "Rain"){
          animation  = weatherRainDay;
        } else if(weatherMain === "Clouds"){
          animation = weatherClouds;
        } else if(weatherMain === "Snow"){
          animation = weatherSnow;
        } else if(weatherMain === "Thunderstorm"){
          animation = weatherThunder;
        } else {
          animation = weatherMist;
        }
        const defaultOptions = {
          loop: true,
          autoplay: true, 
          animationData: animation.default,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        };
        return (
          <Lottie options={defaultOptions}/>
        );
      }
    }

    handleToDoChange(toDoId, val) {
      let curToDoObj = Object.assign({}, this.props.toDo.find(x => x.id === toDoId));
      if((curToDoObj.done === 1 && val === true) || (curToDoObj.done === 0 && val === false)){
        return;
      }
      let that = this;
      Api.updateToDo(toDoId, val).then(f => {
        return f.data;
      }).then((result) => {
        if(val){
          curToDoObj.done = 1;
        } else {
          curToDoObj.done = 0;
        }
        that.props.actions.updateToDo(curToDoObj);
      }).catch(error => {
        console.log(error);
      });
    }

    deleteToDo(toDoId) {
      let curToDoObj = Object.assign({}, this.props.toDo.find(x => x.id === toDoId));
      let that = this;
      Api.deleteToDo(toDoId).then(f => {
        return f.data;
      }).then((result) => {
        that.props.actions.deleteToDo(curToDoObj);
      }).catch(error => {
        console.log(error);
      });
    }

    getFormattedBusinessAddress() {
      let formatted_address = '', entityObj;
      if(this.props.settings && this.props.settings.selected_business_location_id) {
        entityObj = this.props.businessLocation.find(x => x.business_location_id === this.props.settings.selected_business_location_id);
        if(!entityObj) {
          entityObj = this.props.business;
        }
      } else {
        entityObj = this.props.business;
      }
      let unit_name = entityObj.address_unit_name;
      if(unit_name && unit_name !== '') {
        formatted_address = unit_name + ', ';
      }
      let street_number = entityObj.address_street_number;
      if(street_number && street_number !== '') {
        formatted_address = formatted_address + street_number + ' ';
      }
      let street_name = entityObj.address_street_name;
      if(street_name && street_name !== '') {
        formatted_address = formatted_address + street_name + ', ';
      }
      let city = entityObj.address_city;
      if(city && city !== '') {
        formatted_address = formatted_address + city + ', ';
      }
      let state = entityObj.address_state;
      if(state && state !== '') {
        formatted_address = formatted_address + state + ', ';
      }
      let country = entityObj.address_country;
      if(country && country !== '') {
        formatted_address = formatted_address + country + ', ';
      }
      let postal_code = entityObj.address_postal_code;
      if(postal_code && postal_code !== '') {
        formatted_address = formatted_address + postal_code;
      }
      return formatted_address;
    }

    renderClockWidget() {
      let currentTime;
      if(this.props.business.timezone_name) {
        currentTime = moment(this.state.curTime).tz(this.props.business.timezone_name, false);
      } else {
        if(this.state.curTime) {
          currentTime = moment(this.state.curTime);
        } else {
          currentTime = moment();
        }
      }
      let timezoneObj = timezoneOptions.find(x => x.id === this.props.business.timezone_id);
      return (
        <Card className="card-stats">
          <CardBody>
            <div className="statistics statistics-horizontal">
              <div className="info info-horizontal">
                <Row>
                  <Col xs="5">
                    <div className="icon icon-primary icon-circle">
                      <Clock curTime={moment(currentTime).toDate()}/>
                    </div>
                  </Col>
                  <Col className="text-end" xs="7">
                    <h3 className="info-title">{currentTime.format('HH:mm')}</h3>
                    <h6 className="stats-title">TIME</h6>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
          <hr />
          <CardFooter>
            <div className="stats" style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
              <FiGlobe/> {timezoneObj ? timezoneObj.label : 'UTC'}
            </div>
          </CardFooter>
        </Card>
      );
    }

    renderDateWidget() {
      let currentDate;
      if(this.props.business.timezone_name) {
        currentDate = moment().tz(this.props.business.timezone_name, false);
      } else {
        currentDate = moment();
      }
      return (
        <Card className="card-stats">
          <CardBody>
            <div className="statistics statistics-horizontal">
              <div className="info info-horizontal">
                <Row>
                  <Col xs="5">
                      <div className="icon icon-primary icon-circle">
                          <div className="icon-container">
                            <FiCalendar size={35} />
                          </div>
                      </div>
                  </Col>
                  <Col className="text-end" xs="7">
                    <h3 className="info-title" style={{ whiteSpace: 'nowrap' }}>
                      {currentDate.format('Do MMM')}
                    </h3>
                    <h6 className="stats-title">DATE</h6>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
          <hr />
          <CardFooter>
            <div className="stats">
              <FiCalendar/> Today
            </div>
          </CardFooter>
        </Card>
      );
    }

    renderBusinessStatusWidget() {
      let dayTimeStatus = null, dayTimeTitle = null, dayTimeDisplay = null;
      if(this.state.timeStatus && this.state.timeStatus === 2){
        // Business closed
        dayTimeStatus = 2;
        dayTimeTitle = 'Closed today';
        dayTimeDisplay = '--:--'
      } else {
        if(this.state.minTime && this.state.maxTime) {
          let businessMoment = moment().tz(this.props.business.timezone_name, false);
          let [minHours, minMinutes] = this.state.minTime.start.split(':');
          let startMoment = moment(businessMoment).set({'hours': minHours, 'minutes': minMinutes, 'seconds': 0 });
          let [maxHours, maxMinutes] = this.state.maxTime.end.split(':');
          let endMoment = moment(businessMoment).set({'hours': maxHours, 'minutes': maxMinutes, 'seconds': 0 });
          // Business open - find out if currently open
          if(this.state.minTime && this.state.maxTime && businessMoment >= startMoment && businessMoment <= endMoment){
            dayTimeStatus = 1;
            dayTimeTitle = 'Closing Time';
            dayTimeDisplay = endMoment.format('HH:mm');
          } else if(this.state.minTime && businessMoment < startMoment){
            dayTimeStatus = 2;
            dayTimeTitle = 'Opening Time';
            dayTimeDisplay = startMoment.format('HH:mm');
          } else if(this.state.maxTime && businessMoment > endMoment){
            dayTimeStatus = 2;
            dayTimeTitle = 'Closing Time';
            dayTimeDisplay = endMoment.format('HH:mm');
          } else {
            dayTimeStatus = 2;
            dayTimeTitle = '--';
            dayTimeDisplay = '--:--';
          }
        } else {
          dayTimeStatus = 2;
          dayTimeTitle = '--';
          dayTimeDisplay = '--:--';
        }
      }
      return (
        <Card className="card-stats">
          <CardBody>
            <div className="statistics statistics-horizontal">
              <div className="info info-horizontal">
                <Row>
                  <Col xs="5">
                    <div className={`icon icon-circle ${dayTimeStatus === 1 ? "icon-success" : "icon-danger"}`}>
                      <div className="icon-container">
                        <FaCircle size={30} />
                      </div>
                    </div>
                  </Col>
                  <Col className="text-end" xs="7">
                    <h3 className="info-title">{dayTimeDisplay}</h3>
                    <h6 className="stats-title">{dayTimeTitle}</h6>
                  </Col>
                </Row>
              </div>
            </div>
          </CardBody>
          <hr />
          <CardFooter>
            <div className="stats">
              <i className="now-ui-icons arrows-1_refresh-69" /> Updated just now
            </div>
          </CardFooter>
        </Card>
      );
    }

    renderStaffStatusWidget() {
      return (
        <Card className="card-tasks card-staff-status">
          <CardHeader>
            <CardTitle tag="h4">Staff Status</CardTitle>
          </CardHeader>
          <CardBody>
            {this.props.staff && this.props.staff.length > 0 ?
            <Table responsive>
              <tbody>
                {this.props.staff.map((staffObj,) => {
                  // Check if staff member is available at the selected location
                  let foundStaffMap = this.props.businessLocationStaffMap.find(x => x.staff_id === staffObj.id && x.business_location_id === this.props.settings.selected_business_location_id);
                  if(!foundStaffMap) {
                      return null;
                  }
                  let staffHoursObj = this.state.staffHoursData.find(x => x.staff_id === staffObj.id);
                  return (
                  <tr className="staff-row" key={`staffRow${staffObj.id}`}>
                    <td className="img-row">
                      <div className="img-wrapper">
                        <img src={`${CDN_URL}/` + staffObj.staff_img} alt="StaffImg"/>
                      </div>
                    </td>
                    <td className="text-start staff-cell-name">
                      {window.innerWidth < 576 ? staffObj.firstname.charAt(0) + ' ' + staffObj.lastname : staffObj.firstname + ' ' + staffObj.lastname}
                    </td>
                    <td className="text-start staff-cell-status">
                      <FaCircle size={12} color={staffHoursObj ? staffHoursObj.working === 1 ? '#1ab394' : '#ed5565' : '#333333'} style={{ marginBottom: 3, marginRight: 3 }}/> {staffHoursObj ? (staffHoursObj.working === 1 ? 'Working Today' : 'Not Working Today') : 'N/A'}
                    </td>
                    <td className="text-end staff-cell-hours">
                      {staffHoursObj && staffHoursObj.working === 1 ? moment(staffHoursObj.start, 'HH:mm:ss').format('HH:mm') + ' - ' + moment(staffHoursObj.end, 'HH:mm:ss').format('HH:mm') : '--:--'}
                    </td>
                  </tr>
                  )
              })}
              </tbody>
            </Table> : <div className="staff-status-empty-container">No Staff To Display</div>} 
          </CardBody>
          <CardFooter>
            <hr />
            <FiCalendar style={{ marginBottom: 3, marginRight: 5 }}/> {this.props.business.timezone_name ? moment().tz(this.props.business.timezone_name, false).format('ddd, Do MMMM') : moment().format('ddd, Do MMMM')}
          </CardFooter>
        </Card>
      );
    }

    render() {
      return (
      <>
        <PanelHeader size="sm" />
        <div className="content pb-0">
          <Row>
            <Col lg="3" sm="6">
              {this.renderClockWidget()}
            </Col>
            <Col lg="3" sm="6">
              {this.renderDateWidget()}
            </Col>
            <Col lg="3" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <div className="statistics statistics-horizontal">
                    <div className="info info-horizontal">
                      <Row>
                        <Col xs="5">
                          <div className="icon icon-primary icon-circle">
                            {this.loadWeatherAnimation()}
                          </div>
                        </Col>
                        <Col className="text-end" xs="7">
                          <h3 className="info-title">{this.state.weatherData ? Math.round(this.state.weatherData.main.temp - 273.15) : 'N/A'} <div style={{ display: 'inline-block', fontWeight: '400' }}>°C</div></h3>
                          <h6 className="stats-title">Temperature</h6>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
                <hr />
                <CardFooter>
                  <Row>
                    <Col xs="7">
                      <div className="stats" style={{ whiteSpace: 'nowrap' }}>
                        <FiMapPin/> {this.state.weatherData ? this.state.weatherData.name : null}
                      </div>
                    </Col>
                    <Col className="text-end text-muted" xs="5">
                      <div style={{ whiteSpace: 'nowrap' }}>
                        {this.state.weatherData ? this.state.weatherData.weather[0].main : null}
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" sm="6">
              {this.renderBusinessStatusWidget()}
            </Col>
          </Row>
          <Row>
            <Col className="text-center" lg="6" md="12">
              <Card className="card-business" style={this.props.business.business_img ? { backgroundImage: `url("${CDN_URL}/` + this.props.business.business_img + '")' } : null}>
                {this.props.business.business_img ?
                  <div
                    className="card-overlay"
                    style={this.props.business.hub_cover_image_overlay_gradient_start && this.props.business.hub_cover_image_overlay_gradient_end ? (
                        { background: `linear-gradient(160deg, ${this.props.business.hub_cover_image_overlay_gradient_start} 0%, ${this.props.business.hub_cover_image_overlay_gradient_end} 100%)` }
                    ) : (
                        { background: `rgba(0, 0, 0, 0.6)` }
                    )}
                  />
                : null}
                <CardBody>
                  {this.props.business && this.props.business.business_logo ?
                    <img className="card-logo" src={`${CDN_URL}/` + this.props.business.business_logo} alt="BusinessLogo"/>
                  :
                    <CardTitle style={!this.props.business.business_img && !this.props.business.business_img ?  { color: '#000000' } : null}>{this.props.business ? this.props.business.business_name : 'Loading...'}</CardTitle>
                  }
                  <p className="card-description" style={this.props.business.hub_cover_image_address ? { color: this.props.business.hub_cover_image_address } : null}>
                    {this.getFormattedBusinessAddress()}
                  </p>
                </CardBody>
              </Card>
              <Card className="card-to-do card-tasks">
                <CardHeader>
                  <CardTitle tag="h4">Your To Do List</CardTitle>
                </CardHeader>
                <CardBody>
                  {this.props.toDo && this.props.toDo.length > 0 ?
                    <Table responsive>
                    <tbody>
                      {this.props.toDo.map((toDoObj, toDoIndex) => {
                        return (
                          <tr key={'toDo' + toDoIndex}>
                            <td className="text-start">
                              <FormGroup check>
                                <Label check>
                                  <Input defaultChecked={toDoObj.done === 1} type="checkbox" onChange={(e) => this.handleToDoChange(toDoObj.id, e.target.checked)} />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                            </td>
                            <td className="text-start">
                              {toDoObj.task}
                            </td>
                            <td className="td-actions text-end">
                              <Button
                                className="btn-round btn-icon btn-icon-mini btn-neutral"
                                color="danger"
                                id={"toDoTooltip" + toDoIndex}
                                type="button"
                                onClick={() => this.deleteToDo(toDoObj.id)}
                              >
                                <i className="now-ui-icons ui-1_simple-remove" />
                              </Button>
                              <UncontrolledTooltip
                                delay={0}
                                target={"toDoTooltip" + toDoIndex}
                              >
                                Delete
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table> : <div className="to-do-empty-container">Your To Do list List Is Empty</div>}
                </CardBody>
                <CardFooter>
                  <div>
                    <Button color="primary" className="btn-fullwidth" onClick={() => this.setState({ toDoAddVisible: true })}>
                      <FiPlus size={16} color={'#1ab394'} style={{ marginBottom: 3, marginRight: 5 }}/>
                      New To Do
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col className="text-center" lg="6" md="12">
              <Row>
                <Col lg="6" md="6" sm="12">
                  <Card className="card-pricing">
                    <CardBody>
                      <div className="card-icon icon-primary">
                        <div className="card-icon-container">
                          <FiBook style={{ height: '100%' }} size={40} />
                        </div>
                      </div>
                      <CardTitle tag="h3" style={{ fontWeight: '600', marginBottom: '0.4rem' }}>{this.state.bookingCount}</CardTitle>
                      <h3 style={{ marginBottom: 0 }}>Bookings</h3>
                      <h4 className="card-category" style={{ fontSize: 18, paddingTop: 0, marginTop: 5 }}>Today</h4>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" md="6" sm="12">
                  <Card className="card-pricing">
                    <CardBody>
                      <div className="card-icon icon-primary">
                        <div className="card-icon-container">
                          <IoWalkOutline style={{ height: '100%' }} size={40} />
                        </div>
                      </div>
                      <CardTitle tag="h3" style={{ fontWeight: '600', marginBottom: '0.4rem' }}>{this.state.walkInCount}</CardTitle>
                      <h3 style={{ marginBottom: 0 }}>Walk-ins</h3>
                      <h4 className="card-category" style={{ fontSize: 18, paddingTop: 0, marginTop: 5 }}>Today</h4>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="text-center" lg="12" md="12">
                  {this.renderStaffStatusWidget()}
                </Col>
              </Row>
            </Col>
          </Row>
          <ToDoAdd isOpen={this.state.toDoAddVisible} toggleVisible={this.toggleToDoAddVisible} />
        </div>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    business: state.business,
    businessLocation: state.businessLocation,
    businessLocationStaffMap: state.businessLocationStaffMap,
    toDo: state.toDo,
    staff: state.staff,
    settings: state.settings,
    loading: state.loading
  }
}

function mapDispatchToProps(dispatch) {
  return {
      actions: bindActionCreators(serviceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);