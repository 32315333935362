// Icons
import {
    Storefront as StorefrontIcon,
    CalendarBlank as CalendarIcon,
    UserList as UserListIcon,
    ListDashes as ListDashesIcon,
    ShoppingBag as ShoppingBagIcon,
    UsersThree as UsersThreeList,
    Images as ImagesIcon,
    Gear as GearIcon,
    ChalkboardTeacher as ChalkboardTeacherIcon
} from '@phosphor-icons/react';
  
// Admin layout pages
import Home from "views/Home/Home.js";
import Account from "views/Account/Account.js";
import Billing from "views/Billing/Billing.js";
import Referrals from "views/Referrals/Referrals.js";
import Calendar from "views/Calendar/Calendar.js";
import Search from "views/Search/Search.js"
  
import BookingAdd from "views/Booking/BookingAdd.js";
import BookingEdit from "views/Booking/BookingEdit.js";
// Services
import Services from "views/Services/Services.js";
import ServiceAdd from "views/Services/ServiceAdd.js";
import ServiceEdit from "views/Services/ServiceEdit.js";
// Courses
import Courses from "views/Courses/Courses.js";
// Customers
import Customers from "views/Customers/Customers.js";
import CustomerProfile from "views/Customers/CustomerProfile";
// Products
import Products from "views/Products/Products";
import ProductAdd from "views/Products/ProductAdd";
import ProductEdit from "views/Products/ProductEdit";
// Staff
import Staff from "views/Staff/Staff";
import StaffAdd from "views/Staff/StaffAdd";
import StaffEdit from "views/Staff/StaffEdit";
import Permissions from 'views/Staff/Permissions';
import StaffAccounts from 'views/Staff/StaffAccounts';

import Gallery from "views/Gallery/Gallery";
import GalleryAdd from "views/Gallery/GalleryAdd";
import GalleryEdit from "views/Gallery/GalleryEdit";

import Settings from "views/Settings/Settings";
// Settings screens
import BookingPreferences from "views/Settings/Bookings/BookingPreferences";
import BookingTerms from "views/Settings/Bookings/BookingTerms";
import HubAppearance from "views/Settings/Hub/HubAppearance";
import HubNotifications from "views/Settings/Hub/HubNotifications";
import RevenueProtection from "views/Settings/Bookings/RevenueProtection";
import WaitList from "views/Settings/Bookings/WaitList";
import PushNotifications from "views/Settings/App/PushNotifications";
import InstagramIntegration from "views/Settings/App/InstagramIntegration";
import AppPreferences from "views/Settings/App/AppPreferences";
import AppStatus from "views/Settings/App/AppStatus";
// Integrations
import Stripe from "views/Settings/Integrations/Stripe";
// Business locations
import BusinessLocations from "views/Settings/Business/BusinessLocations";
import BusinessLocationAdd from "views/Settings/Business/BusinessLocationAdd";
import BusinessLocationEdit from "views/Settings/Business/BusinessLocationEdit";

import BusinessClosedDates from "views/Settings/Business/BusinessClosedDates";
import Emails from "views/Settings/Business/Emails";

import BusinessSettings from "views/Settings/Business/BusinessSettings";
import BusinessSchedules from "views/Settings/Business/BusinessSchedules";
import BookingWidget from "views/Settings/Widget/BookingWidget";

import checkAccessPermission from 'utilities/accessPermission';
  
const routes = [
    {
        path: "home",
        name: "Home",
        icon: <StorefrontIcon size={32} weight="light" />,
        component: checkAccessPermission(Home),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "calendar",
        name: "Calendar",
        icon: <CalendarIcon size={32} weight="light" />,
        component: checkAccessPermission(Calendar),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2]
    },
    {
        path: "add_booking",
        name: "Add Booking",
        component: checkAccessPermission(BookingAdd),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['booking_create'],
        businessAccountAccess: [1,2]
    },
    {
        path: "edit_booking",
        name: "Edit Booking",
        component: checkAccessPermission(BookingEdit),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['booking_update'],
        businessAccountAccess: [1,2]
    },
    {
        path: "customers",
        name: "Customers",
        icon: <UserListIcon size={32} weight="light" />,
        component: checkAccessPermission(Customers),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2]
    },
    {
        path: "customer_profile",
        name: "Customer",
        component: checkAccessPermission(CustomerProfile),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2]
    },
    {
        path: "services",
        name: "Services",
        icon: <ListDashesIcon size={32} weight="light" />,
        component: checkAccessPermission(Services),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "add_service",
        name: "Add Service",
        component: checkAccessPermission(ServiceAdd),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['service_create'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "edit_service",
        name: "Edit Service",
        component: checkAccessPermission(ServiceEdit),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "courses",
        name: "Courses",
        component: checkAccessPermission(Courses),
        icon: <ChalkboardTeacherIcon size={32} weight="light" />,
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "products",
        name: "Products",
        icon: <ShoppingBagIcon size={32} weight="light"/>,
        component: checkAccessPermission(Products),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,3],
    },
    {
        path: "add_product",
        name: "Add Product",
        component: checkAccessPermission(ProductAdd),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['product_create'],
        businessAccountAccess: [1,3],
    },
    {
        path: "edit_product",
        name: "Edit Product",
        component: checkAccessPermission(ProductEdit),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,3],
    },
    {
        path: "search",
        name: "Search",
        component: checkAccessPermission(Search),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2]
    },
    {
        path: "staff",
        name: "Staff",
        icon: <UsersThreeList size={32} weight="light"/>,
        component: checkAccessPermission(Staff),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "add_staff",
        name: "Add Staff Member",
        component: checkAccessPermission(StaffAdd),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['staff_create'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "edit_staff",
        name: "Edit Staff Member",
        component: checkAccessPermission(StaffEdit),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['staff_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "gallery",
        name: "Gallery",
        icon: <ImagesIcon size={32} weight="light"/>,
        component: checkAccessPermission(Gallery),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "add_gallery_image",
        name: "Add Gallery Image",
        component: checkAccessPermission(GalleryAdd),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['gallery_create'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "edit_gallery_image",
        name: "Edit Gallery Image",
        component: checkAccessPermission(GalleryEdit),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['gallery_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "account",
        name: "Account",
        component: Account,
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "billing",
        name: "Billing",
        component: checkAccessPermission(Billing),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['billing_read'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "referrals",
        name: "Referrals",
        component: Referrals,
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "settings",
        name: "Settings",
        icon: <GearIcon size={32} weight="light"/>,
        component: checkAccessPermission(Settings),
        layout: "admin",
        onSideMenu: true,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "emails",
        name: "Emails",
        component: checkAccessPermission(Emails),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "integrations/stripe",
        name: "Stripe",
        component: checkAccessPermission(Stripe),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2]
    },
    {
        path: "booking-preferences",
        name: "Booking - Preferences",
        component: checkAccessPermission(BookingPreferences),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "booking-terms",
        name: "Booking - T&Cs",
        component: checkAccessPermission(BookingTerms),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "revenue-protection",
        name: "Revenue Protection",
        component: checkAccessPermission(RevenueProtection),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2]
    },
    {
        path: "wait-list",
        name: "Wait List",
        component: checkAccessPermission(WaitList),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['waitlist_read'],
        businessAccountAccess: [1]
    },
    {
        path: "app-status",
        name: "App Status",
        component: checkAccessPermission(AppStatus),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,3]
    },
    {
        path: "app-preferences",
        name: "App Preferences",
        component: checkAccessPermission(AppPreferences),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,3]
    },
    {
        path: "push-notifications",
        name: "Push Notifications",
        component: checkAccessPermission(PushNotifications),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['notification_read'],
        businessAccountAccess: [1,3]
    },
    {
        path: "instagram-integration",
        name: "Instagram",
        component: checkAccessPermission(InstagramIntegration),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,3]
    },
    {
        path: "business-locations",
        name: "Business Locations",
        component: checkAccessPermission(BusinessLocations),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2,3]
    },
    {
        path: "add-business-location",
        name: "Add Business Location",
        component: checkAccessPermission(BusinessLocationAdd),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['location_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "edit-business-location",
        name: "Edit Business Location",
        component: checkAccessPermission(BusinessLocationEdit),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['location_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "business-settings",
        name: "Business Settings",
        component: checkAccessPermission(BusinessSettings),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2,3],
    },
    {
        path: "business-schedules",
        name: "Business Schedules",
        component: checkAccessPermission(BusinessSchedules),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['date_update'],
        businessAccountAccess: [1,2,3],
    },
    {
        path: "business-closed-dates",
        name: "Closed Dates",
        component: checkAccessPermission(BusinessClosedDates),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['date_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "hub-appearance",
        name: "Hub - Appearance",
        component: checkAccessPermission(HubAppearance),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['setting_update'],
        businessAccountAccess: [1,2,3]
    },
    {
        path: "hub-notifications",
        name: "Hub - Notifications",
        component: checkAccessPermission(HubNotifications),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2]
    },
    {
        path: "booking-widget",
        name: "Booking Widget",
        component: checkAccessPermission(BookingWidget),
        layout: "admin",
        onSideMenu: false,
        businessAccountAccess: [1,2]
    },
    {
        path: "staff/permissions",
        name: "Permissions",
        component: checkAccessPermission(Permissions),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['permission_update'],
        businessAccountAccess: [1,2]
    },
    {
        path: "staff/accounts",
        name: "Accounts",
        component: checkAccessPermission(StaffAccounts),
        layout: "admin",
        onSideMenu: false,
        accessPermission: ['permission_update'],
        businessAccountAccess: [1,2]
    }
];
  
export default routes;  