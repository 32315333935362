import React from "react";

// reactstrap components
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody
} from "reactstrap";
import {
    FiSquare,
    FiArrowRight,
    FiSliders,
    FiXOctagon,
    FiUsers,
    FiFileText,
    FiSend,
    FiActivity,
    FiTool,
    FiBell,
    FiMonitor,
    FiCalendar,
    FiMapPin,
    FiSlash,
    FiMail,
    FiLock,
    FiUserCheck
} from "react-icons/fi";
// Logos
import stripeIcon from "../../assets/img/logos/stripe/stripeIcon.svg";
import instagramLogo from "../../assets/img/logos/instagram/instagram.svg";
// Components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
// Decorators
import withRouter from "utilities/withRouter";

class Settings extends React.Component {

    renderAppCard() {
        if(![1,3].includes(this.props.business.business_account_type_id)) {
            return;
        }
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>App</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    <div className="settings-row" onClick={() => this.props.navigate('/admin/app-status')}>
                        <div>
                            <FiActivity size={20} style={{ marginRight: 10 }} />Status
                        </div>
                        <div>
                            <FiArrowRight size={20}/>
                        </div>
                    </div>
                    {this.props.userRole?.notification?.read === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/push-notifications')}>
                            <div>
                                <FiSend size={20} style={{ marginRight: 10 }} />Push Notifications
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {this.props.userRole?.setting?.update === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/app-preferences')}>
                            <div>
                                <FiSliders size={20} style={{ marginRight: 10 }} />Preferences
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        );
    }
    
    renderBookingsCard() {
        if(this.props.business.business_account_type_id === 3) {
            return;
        }
        const bookingPlan = [1,2].includes(this.props.business.business_account_type_id);
        if((bookingPlan && this.props.userRole?.setting?.update === false) && (this.props.business.business_account_type_id === 1 && this.props.userRole?.waitlist?.read === false)) {
            return null;
        }
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>Bookings</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    {bookingPlan && this.props.userRole?.setting?.update === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/booking-preferences')}>
                            <div>
                                <FiSliders size={20} style={{ marginRight: 10 }} />Preferences
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {this.props.userRole?.setting?.update === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/booking-terms')}>
                            <div>
                                <FiFileText size={20} style={{ marginRight: 10 }} />T&Cs
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {bookingPlan && this.props.userRole?.setting?.update === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/revenue-protection')}>
                            <div>
                                <FiXOctagon size={20} style={{ marginRight: 10 }} />Revenue Protection
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {this.props.business.business_account_type_id === 1 && this.props.userRole?.waitlist?.read && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/wait-list')}>
                            <div>
                                <FiUsers size={20} style={{ marginRight: 10 }} />Wait List
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        );
    }
    
    renderBusinessCard() {
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>Business</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    <div className="settings-row" onClick={() => this.props.navigate('/admin/business-locations')}>
                        <div>
                            <FiMapPin size={20} style={{ marginRight: 10 }} />Locations
                        </div>
                        <div>
                            <FiArrowRight size={20}/>
                        </div>
                    </div>
                    {this.props.userRole?.setting?.update && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/business-settings')}>
                            <div>
                                <FiTool size={20} style={{ marginRight: 10 }} />Settings
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {this.props.userRole?.date?.update && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/business-schedules')}>
                            <div>
                                <FiCalendar size={20} style={{ marginRight: 10 }} />Schedules (Legacy)
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {this.props.userRole?.date?.update && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/business-closed-dates')}>
                            <div>
                                <FiSlash size={20} style={{ marginRight: 10 }} />Closed Dates
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {this.props.userRole?.setting?.update && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/emails')}>
                            <div>
                                <FiMail size={20} style={{ marginRight: 10 }} />Emails
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        );
    }

    renderIntegrationsCard() {
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>Integrations</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    {[1,3].includes(this.props.business.business_account_type_id) && this.props.userRole?.setting?.update === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/instagram-integration')}>
                            <div>
                                <img src={instagramLogo} alt="Stripe logo" style={{ height: 20, marginRight: 10 }} />Instagram
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    {[1,2].includes(this.props.business.business_account_type_id) && this.props.userRole?.setting?.update === true && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/integrations/stripe')}>
                            <div>
                                <img src={stripeIcon} alt="Stripe logo" style={{ height: 20, marginRight: 10 }} />Stripe
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        );
    }

    renderStaffCard() {
        if(this.props.userRole.permission?.update !== true) {
            return null;
        }
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>Staff</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    <div className="settings-row" onClick={() => this.props.navigate('/admin/staff/accounts')}>
                        <div>
                            <FiUserCheck size={20} style={{ marginRight: 10 }} />Accounts
                        </div>
                        <div>
                            <FiArrowRight size={20}/>
                        </div>
                    </div>
                    <div className="settings-row" onClick={() => this.props.navigate('/admin/staff/permissions')}>
                        <div>
                            <FiLock size={20} style={{ marginRight: 10 }} />Permissions
                        </div>
                        <div>
                            <FiArrowRight size={20}/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    renderHubCard() {
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>Hub</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    {this.props.userRole?.setting?.update && (
                        <div className="settings-row" onClick={() => this.props.navigate('/admin/hub-appearance')}>
                            <div>
                                <FiSquare size={20} style={{ marginRight: 10 }} />Appearance
                            </div>
                            <div>
                                <FiArrowRight size={20}/>
                            </div>
                        </div>
                    )}
                    <div className="settings-row" onClick={() => this.props.navigate('/admin/hub-notifications')}>
                        <div>
                            <FiBell size={20} style={{ marginRight: 10 }} />Notifications
                        </div>
                        <div>
                            <FiArrowRight size={20}/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    renderWidgetCard() {
        if(![1,2].includes(this.props.business.business_account_type_id)) {
            return;
        }
        return (
            <Card>
                <CardHeader>
                    <h5 className="title" style={{ marginBottom: 0 }}>Booking Widget</h5>
                </CardHeader>
                <CardBody style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                    <div className="settings-row" onClick={() => this.props.navigate('/admin/booking-widget')}>
                        <div>
                            <FiMonitor size={20} style={{ marginRight: 10 }} />Details
                        </div>
                        <div>
                            <FiArrowRight size={20}/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    render() {
        return (
        <>
            <PanelHeader
                size="md"
                content={
                    <Row>
                        <Col xs={12} lg={10} xl={8} className="ms-auto me-auto">
                            <h2 className="panel-header-title text-center" style={this.props.business.hub_header_title ? { color: this.props.business.hub_header_title } : null}>Settings</h2>
                        </Col>
                    </Row>
                }
            />
            <div className="content">
                <Row>
                    <Col xs={12} sm={10} md={8} lg={6} xl={4} className="ms-auto me-auto">
                        {this.renderAppCard()}
                        {this.renderBookingsCard()}
                        {this.renderBusinessCard()}
                        {this.renderIntegrationsCard()}
                        {this.renderStaffCard()}
                        {this.renderHubCard()}
                        {this.renderWidgetCard()}
                    </Col>
                </Row>
            </div>
        </>
        );
    }
}

export default withRouter(Settings);